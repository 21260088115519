<script lang="ts" setup>
import { useI18n } from 'nuxt-i18n-composable'

import type { ButtonColor } from '@/components/button/SkrButton.vue'
import SkrButton from '@/components/button/SkrButton.vue'

const { t } = useI18n()

interface Props {
  title: string
  maxWidth?: string
  actionText?: string
  actionColor?: ButtonColor
  cancelText?: string
  disabled?: boolean
  loading?: boolean
  persistent?: boolean
  dataCy?: string
}

withDefaults(defineProps<Props>(), {
  maxWidth: '570',
})

const emit = defineEmits<{
  (event: 'action'): void
  (event: 'close'): void
}>()
</script>

<template>
  <v-dialog :value="true" :max-width="maxWidth" :persistent="persistent">
    <v-card :data-cy="dataCy">
      <v-card-title>
        {{ title }}
      </v-card-title>
      <v-card-text>
        <slot />
      </v-card-text>
      <slot name="actions">
        <v-divider />
        <v-card-actions>
          <skr-button
            data-cy="dialog_cancel_button"
            type="secondary"
            size="xl"
            :color="actionColor"
            @click="emit('close')"
          >
            {{ cancelText ?? t('global.no_thanks') }}
          </skr-button>
          <v-spacer />
          <skr-button
            data-cy="dialog_action_button"
            size="xl"
            :color="actionColor"
            :disabled="disabled"
            :loading="loading"
            @click="emit('action')"
          >
            {{ actionText ?? t('global.accept') }}
          </skr-button>
        </v-card-actions>
      </slot>
    </v-card>
  </v-dialog>
</template>
